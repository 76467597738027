import imageUrlBuilder from '@sanity/image-url'
import { SanityImageSource } from '@sanity/image-url/lib/types/types'
import type { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder'
import clientConfig from '../../../client-config'

const builder = imageUrlBuilder(clientConfig.sanity)

export function imageUrlFor(source: SanityImageSource): ImageUrlBuilder {
  return builder.image(source)
}
