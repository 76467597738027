import { useAuth0 } from '@auth0/auth0-react'
import { Subscribe } from '@react-rxjs/core'
import React, { FC, useCallback, useState } from 'react'
import { useRegistrationTypes } from '../../../lib/registration'
import { RegistrationTypeSelector } from '../../registration-form/RegistrationTypeSelector'
import { RegistrationFormPractitioner } from '../../registration-form/RegistrationFormPractitioner'
import { RegistrationFormPrivateLabelPractitioner } from '../../registration-form/RegistrationFormPrivateLabelPractitioner'
import { RegistrationFormPrivateLabelUnlicensed } from '../../registration-form/RegistrationFormPrivateLabelUnlicensed'
import { RegistrationFormPatient, Title } from '../../registration-form/RegistrationFormPatient'
import { RegistrationFormStudent } from '../../registration-form/RegistrationFormStudent'
import * as Styled from './styled'

export const StandardRegistration: FC = () => {
  const { loginWithRedirect } = useAuth0()
  const login = useCallback(() => loginWithRedirect(), [loginWithRedirect])

  const [submitting, setSubmitting] = useState(false)

  const {
    allowPractitioner,
    allowPLPractitioner,
    allowPLUnlicensed,
    allowPatient,
    allowPatientZipCode,
    allowStudent,
    selectedType,
    isSelectionAllowed,
    selectType,
  } = useRegistrationTypes()

  const setIsUseZipCodeRegistration = useCallback(
    (isZipCodeSelected: boolean) =>
      isZipCodeSelected ? selectType('patient-zip-code') : selectType('patient'),
    [selectType]
  )

  const isPLRegistration =
    selectedType === 'private-label-unlicensed' || selectedType === 'private-label-practitioner'

  return (
    <Styled.Container>
      <Styled.TopBar>
        Already a member? <Styled.SignInButton onClick={login}>Sign In</Styled.SignInButton>
      </Styled.TopBar>
      <Styled.Content>
        <Subscribe>
          <Styled.FormContainer>
            {isSelectionAllowed && (
              <RegistrationTypeSelector
                allowPractitioner={allowPractitioner && !isPLRegistration}
                allowPLPractitioner={allowPLPractitioner && isPLRegistration}
                allowPLUnlicensed={allowPLUnlicensed && isPLRegistration}
                allowPatient={allowPatient && !isPLRegistration}
                allowStudent={allowStudent && !isPLRegistration}
                selectedType={selectedType}
                selectType={selectType}
                disabled={submitting}
              />
            )}
            {selectedType === 'practitioner' && (
              <RegistrationFormPractitioner setSubmitting={setSubmitting} />
            )}
            {selectedType === 'private-label-practitioner' && (
              <RegistrationFormPrivateLabelPractitioner setSubmitting={setSubmitting} />
            )}
            {selectedType === 'private-label-unlicensed' && (
              <RegistrationFormPrivateLabelUnlicensed setSubmitting={setSubmitting} />
            )}
            {(selectedType === 'patient' || selectedType === 'patient-zip-code') && (
              <RegistrationFormPatient
                allowPatientZipCode={allowPatientZipCode}
                isUseZipCode={selectedType === 'patient-zip-code'}
                setIsUseZipCode={setIsUseZipCodeRegistration}
                setSubmitting={setSubmitting}
              >
                <Title>Patient Registration</Title>
              </RegistrationFormPatient>
            )}
            {selectedType === 'student' && (
              <RegistrationFormStudent setSubmitting={setSubmitting} />
            )}
          </Styled.FormContainer>
        </Subscribe>
      </Styled.Content>
    </Styled.Container>
  )
}
