import { ButtonLink } from '@designsforhealth/dfh-react-components'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 1px;
`

export const TopBar = styled.div`
  text-align: right;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.grayscale.black};
`

export const SignInButton = styled(ButtonLink)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.component.link};
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const FormContainer = styled.div`
  max-width: 525px;
  width: 100%;
`
