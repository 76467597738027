import { graphql } from 'gatsby'
import React from 'react'
import logoImage from '../../images/logo-designs-for-health.svg'
import { useSiteSettings } from '../../lib/sanity/hooks/useSiteSettings'
import { imageUrlFor } from '../../lib/sanity/image-url'

interface SiteLogoProps {
  alt?: string
  className?: string
}

export const SiteLogo: React.FC<SiteLogoProps> = ({ alt, className }) => {
  const sanitySiteSettings = useSiteSettings()
  return (
    <img
      className={className}
      src={imageUrlFor(sanitySiteSettings?.mainLogo?._rawAsset).url() || logoImage}
      alt={alt || 'Designs for Health logo'}
    />
  )
}

export const siteLogoFragment = graphql`
  fragment siteLogo on SanitySiteSettings {
    mainLogo {
      _rawAsset
    }
  }
`
