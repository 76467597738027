import styled from 'styled-components'
import { SiteLogo } from '../../global/SiteLogo'
import dfhPatternGray from '../../../images/registration/dfh-pattern-gray.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  min-height: 80vh;
  @media (min-width: 992px) {
    min-height: 800px;
  }
`

export const LeftSection = styled.div`
  flex: 0 0 37.5%;
  max-width: 37.5%;
  background-color: ${({ theme }) => theme.colors.brandBlue.dark100};
  background-image: url(${dfhPatternGray});
  background-repeat: no-repeat;
  background-size: 1439px 480px;
  background-position: -856px 265px;
  padding: 40px;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  @media (min-width: 992px) {
    display: flex;
  }
`

export const Logo = styled(SiteLogo)`
  display: block;
  width: 193px;
  margin-bottom: 60px;
`

export const Heading = styled.h1`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: bold;
  font-size: 36px;
  line-height: 42.84px;
  color: ${({ theme }) => theme.colors.grayscale.white};
  margin: 0;
`

export const LeftSectionBottom = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const AtomIcon = styled.img`
  display: block;
  width: 30px;
  margin-bottom: 21px;
`

export const LeftSectionBottomTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.grayscale.white};
  margin-bottom: 10px;
`

export const ProgressBar = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  gap: 5px;
`

export const ProgressBarIndicator = styled.div`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  width: 25%;
  height: 2px;
  opacity: 0.34;
`

export const ProgressBarIndicatorActive = styled(ProgressBarIndicator)`
  opacity: 1;
`

export const RightSection = styled.div`
  padding: 20px;
  width: 100%;
  @media (min-width: 992px) {
    flex: 0 0 62.5%;
    max-width: 62.5%;
    padding: 40px;
  }
`
