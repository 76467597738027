/**
 * @type {{sanity: {projectId: String, dataset: String, graphqlTag: String}}}
 */
const clientConfig = {
  sanity: {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID || 'lemtscrs',
    dataset: process.env.GATSBY_SANITY_DATASET || 'production',
    graphqlTag: process.env.GATSBY_SANITY_GRAPHQL_TAG || 'default',
  },
}

module.exports = clientConfig
