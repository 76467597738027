import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { RegistrationLayout } from './RegistrationLayout'
import { StandardRegistration } from './StandardRegistration'

export const RegistrationPage: FC = () => {
  return (
    <>
      <Helmet>
        <title>Register for Your DFH Account - Get Superior Natural Supplements</title>
        <meta
          name="description"
          content="Register with DFH as a practitioner or patient and start your journey to a healthier lifestyle with high quality supplements, vitamins, resources, and more."
        />
      </Helmet>
      <RegistrationLayout>
        <StandardRegistration />
      </RegistrationLayout>
    </>
  )
}
