import {
  DropdownBasic,
  EmailInput,
  TelInput,
  TextInput,
} from '@designsforhealth/dfh-react-components'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { firstValueFrom } from 'rxjs'
import { useReCaptcha } from '../../lib/auth/hooks/useReCaptcha'
import { langCodes } from '../../lib/config/lang'
import { logAndCaptureException } from '../../lib/monitoring'
import {
  RegistrationAddressFieldValues,
  RegistrationData,
  RegistrationFieldValues,
  RegistrationPractitionerSelectOptions,
  RegistrationPrivateLabelPractitionerUserMetadata,
  SubmitDataValidationHandler,
  useRegistrationForm,
  useSetLoginFactory,
  validatePasswordsMatch,
  validateTerms,
} from '../../lib/registration'
import { useLoginAfterRegistration } from '../../lib/registration/request/login-after'
import {
  formDisabledStates,
  formProcessingStates,
  useRegistrationState,
} from '../../lib/registration/state'
import { useSiteSettings } from '../../lib/sanity/hooks/useSiteSettings'
import { LoginAfterRegistrationErrorMessage } from './LoginAfterRegistrationErrorMessage'
import { FileUpload } from '../upload/FileUpload'
import { RegistrationFormAddress } from './RegistrationFormAddress'
import { RegistrationFormHelpButton } from './RegistrationFormHelpButton'
import { RegistrationFormPasswordInputs } from './RegistrationFormPasswordInputs'
import { RegistrationFormReCaptcha } from './RegistrationFormReCaptcha'
import { RegistrationFormSimpleTerms } from './RegistrationFormSimpleTerms'
import { RegistrationFormSubmit } from './RegistrationFormSubmit'
import { RegistrationMessage } from './RegistrationMessage'
import { RegistrationUserActionRequired } from './RegistrationUserActionRequired'
import {
  COMPANY_NAME_MAX_LENGTH,
  companyNameMaxLengthRule,
  EMAIL_MAX_LENGTH,
  emailMaxLengthRule,
  FIRST_NAME_MAX_LENGTH,
  firstNameMaxLengthRule,
  LAST_NAME_MAX_LENGTH,
  lastNameMaxLengthRule,
  PHONE_MAX_LENGTH,
  PHONE_MIN_LENGTH,
  phoneMaxLengthRule,
  phoneMinLengthRule,
} from '../../lib/form/fieldLengthRules'
import { DocumentType, SelectFieldOption, SpecialtyAction } from '../../lib/form'
import {
  privateLabelPractitionerRegistrationRequest$,
  setPrivateLabelPractitionerRegistrationInput,
  useLatestPrivateLabelPractitionerRegistrationInput,
} from '../../lib/registration/request/private-label-practitioner'
import * as Styled from './styled'

export type RegistrationFormPrivateLabelPractitionerProps = {
  setSubmitting?: (submitting: boolean) => void
}

type RegistrationPrivateLabelPractitionerFieldValues = RegistrationFieldValues &
  RegistrationAddressFieldValues & {
    companyName: string
    phone: string
    licenseNumber: string
    certificateUrl?: string
    specialty: {
      label: string
      value: string
      practitionerAction?: SpecialtyAction
      practitionerDocument?: DocumentType
    }
  }

const otherSpecialtyType = (options: readonly SelectFieldOption[]): SelectFieldOption | undefined =>
  options.find(({ label }) => label === 'Other')

const parseFieldValuesToRequestData = ({
  firstName,
  lastName,
  email,
  password,
  companyName,
  phone,
  licenseNumber,
  certificateUrl,
  specialty,
  street1,
  street2,
  city,
  state,
  zipCode,
  country,
}: RegistrationPrivateLabelPractitionerFieldValues): RegistrationData &
  RegistrationPrivateLabelPractitionerUserMetadata => {
  return {
    firstName,
    lastName,
    email,
    password,
    type: 'private_label_practitioner',
    company_name: companyName,
    phone,
    license_number: licenseNumber,
    certificate_url: certificateUrl,
    specialty: JSON.stringify(specialty),
    address: JSON.stringify({
      street1,
      street2,
      city,
      state,
      zip_code: zipCode,
      country,
    }),
  }
}

const submitDataValidationHandlers: ReadonlyArray<
  SubmitDataValidationHandler<RegistrationPrivateLabelPractitionerFieldValues>
> = [validatePasswordsMatch, validateTerms]

export const RegistrationFormPrivateLabelPractitioner: React.VFC<RegistrationFormPrivateLabelPractitionerProps> =
  ({
    setSubmitting = () => {
      // noop
    },
  }) => {
    useLatestPrivateLabelPractitionerRegistrationInput()
    useLoginAfterRegistration()
    useSetLoginFactory()

    const [
      { messages, dismissMessage, patterns },
      {
        register,
        watch,
        setValue,
        handleSubmit,
        setError,
        errors,
        control,
        formState: { isSubmitting },
      },
    ] = useRegistrationForm<RegistrationPrivateLabelPractitionerFieldValues>({
      submitDataValidationHandlers,
    })

    const formErrorsAlertRef = useRef<HTMLFormElement>(null)

    const currentSpecialty = watch('specialty')
    const { lang } = useSiteSettings() || {}
    const [selectOptions, setSelectOptions] = useState<RegistrationPractitionerSelectOptions>({
      specialty: [],
      brand: [],
    })

    useEffect(() => {
      switch (lang) {
        case langCodes.en_CA:
          import('../../lib/registration/options/en-ca/practitioner')
            .then((options) => setSelectOptions(options.default))
            .catch(logAndCaptureException)
          break
        default:
          import('../../lib/registration/options/en-us/practitioner')
            .then((options) => setSelectOptions(options.default))
            .catch(logAndCaptureException)
          break
      }
    }, [lang])

    const { name: registrationState } = useRegistrationState()
    const processing = isSubmitting || formProcessingStates.includes(registrationState)
    const disabled = processing || formDisabledStates.includes(registrationState)

    const { captchaError, validateCaptcha, reCaptchaProps } = useReCaptcha()

    const scrollErrorsRefIntoView = () => {
      const formErrorsEl = formErrorsAlertRef.current
      if (formErrorsEl) {
        formErrorsEl.scrollIntoView({ behavior: 'smooth' })
      }
    }

    const onSubmit = useCallback(
      async (fieldValues: RegistrationPrivateLabelPractitionerFieldValues) => {
        if (
          currentSpecialty?.practitionerAction === SpecialtyAction.LicenseAndUpload &&
          !fieldValues.certificateUrl &&
          !fieldValues.licenseNumber
        ) {
          setError('licenseNumber', {
            type: 'validate',
            message: 'You are required to provide a license number or upload a certificate.',
          })
          return
        }
        const isValidCaptcha = !!validateCaptcha()
        if (!isValidCaptcha) {
          return
        }
        setPrivateLabelPractitionerRegistrationInput({
          input: parseFieldValuesToRequestData(fieldValues),
        })
        await firstValueFrom(privateLabelPractitionerRegistrationRequest$)
      },
      [validateCaptcha, currentSpecialty?.practitionerAction, setError]
    )

    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        scrollErrorsRefIntoView()
      }
    }, [errors])

    useEffect(() => {
      setSubmitting(processing)
    }, [setSubmitting, processing])

    const userActionRequiredMessage = useMemo(
      () => messages.find((message) => !!message.actions?.includes('user_action_required')),
      [messages]
    )

    if (userActionRequiredMessage) {
      return <RegistrationUserActionRequired message={userActionRequiredMessage} />
    }

    return (
      <Styled.RegistrationForm
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        ref={formErrorsAlertRef}
      >
        <Styled.LegalDisclaimer>
          You may use this form only if you are a healthcare practitioner creating an account under
          under your individual name, not the name of a registered business entity, and desire to
          purchase products on a wholesale basis.
          <br />
          <br />
          If you wish to establish an account under the name of a business entity, then you will
          have the opportunity to convert your account to a business entity account at any time
          after you complete registration by contacting your DFH sales representative, subject to
          additional terms and conditions. Conversion is permitted only for valid business entities
          in good standing.
        </Styled.LegalDisclaimer>
        <Styled.Title>Account Registration</Styled.Title>
        {messages.map((message, index) => (
          <RegistrationMessage
            key={index}
            message={message}
            onDismiss={() => dismissMessage(message)}
          />
        ))}
        {registrationState === 'login_error' && <LoginAfterRegistrationErrorMessage />}
        <div>
          {errors && Object.keys(errors).length > 0 && (
            <Styled.Alert type="error">
              Please review the form for any missing or required field(s).
            </Styled.Alert>
          )}
          <Styled.FieldsRow>
            <Styled.HalfColumn>
              <TextInput
                autoComplete="given-name"
                label="Practitioner/Owner First Name"
                name="firstName"
                register={register}
                errors={errors}
                required
                disabled={disabled}
                defaultValue=""
                maxLength={FIRST_NAME_MAX_LENGTH}
                validations={firstNameMaxLengthRule}
              />
            </Styled.HalfColumn>
            <Styled.HalfColumn>
              <TextInput
                autoComplete="family-name"
                label="Practitioner/Owner Last Name"
                name="lastName"
                register={register}
                errors={errors}
                required
                disabled={disabled}
                defaultValue=""
                maxLength={LAST_NAME_MAX_LENGTH}
                validations={lastNameMaxLengthRule}
              />
            </Styled.HalfColumn>
          </Styled.FieldsRow>
          <Styled.FieldsRow>
            <TextInput
              autoComplete="organization"
              label="Practice Name"
              name="companyName"
              register={register}
              errors={errors}
              required
              disabled={disabled}
              defaultValue=""
              maxLength={COMPANY_NAME_MAX_LENGTH}
              validations={companyNameMaxLengthRule}
            />
          </Styled.FieldsRow>
          <Styled.FieldsRow>
            <EmailInput
              autoComplete="email"
              label="Practitioner Email Address"
              name="email"
              register={register}
              errors={errors}
              required
              disabled={disabled}
              defaultValue=""
              validations={{
                ...emailMaxLengthRule,
                pattern: patterns.email,
              }}
              maxLength={EMAIL_MAX_LENGTH}
            />
          </Styled.FieldsRow>
          <Styled.FieldsRow>
            <TelInput
              autoComplete="tel"
              label="Practitioner Phone Number"
              name="phone"
              register={register}
              errors={errors}
              required
              disabled={disabled}
              defaultValue=""
              maxLength={PHONE_MAX_LENGTH}
              minLength={PHONE_MIN_LENGTH}
              validations={{
                ...phoneMaxLengthRule,
                ...phoneMinLengthRule,
              }}
            />
          </Styled.FieldsRow>
          <RegistrationFormPasswordInputs
            disabled={disabled}
            defaultValue=""
            errors={errors}
            register={register}
            watch={watch}
          />
          <Styled.Title>
            <Styled.TitleHelper>Please tell us about your practice:</Styled.TitleHelper>
          </Styled.Title>
          <Styled.FieldsRow>
            <DropdownBasic
              label="Practice Specialty"
              name="specialty"
              control={control}
              errors={errors}
              options={selectOptions.specialty}
              required
              disabled={disabled}
              defaultValue={otherSpecialtyType(selectOptions.specialty)}
            />
          </Styled.FieldsRow>
          {currentSpecialty?.practitionerAction === SpecialtyAction.License && (
            <Styled.FieldsRow>
              <TextInput
                label="Practitioner License #"
                name="licenseNumber"
                register={register}
                errors={errors}
                required
                disabled={disabled}
                defaultValue=""
              />
              <RegistrationFormHelpButton>
                Please provide your license number so that we may verify your license status and
                qualifications.
              </RegistrationFormHelpButton>
            </Styled.FieldsRow>
          )}
          {currentSpecialty?.practitionerAction === SpecialtyAction.Upload && (
            <FileUpload
              document={currentSpecialty?.practitionerDocument}
              name="certificateUrl"
              setValue={setValue}
              register={register}
              errors={errors}
              required
            />
          )}
          {currentSpecialty?.practitionerAction === SpecialtyAction.LicenseAndUpload && (
            <>
              <Styled.FieldsRow>
                <TextInput
                  label="Practitioner License #"
                  name="licenseNumber"
                  register={register}
                  disabled={disabled}
                  defaultValue=""
                />
                <RegistrationFormHelpButton>
                  Please provide your license number so that we may verify your license status and
                  qualifications.
                </RegistrationFormHelpButton>
              </Styled.FieldsRow>
              <FileUpload
                document={currentSpecialty?.practitionerDocument}
                name="certificateUrl"
                setValue={setValue}
                register={register}
              />
              {errors.licenseNumber && (
                <Styled.Alert type="error">{errors.licenseNumber.message}</Styled.Alert>
              )}
            </>
          )}
          <Styled.Title>
            <Styled.TitleHelper>Business address:</Styled.TitleHelper>
          </Styled.Title>
          <RegistrationFormAddress
            disabled={disabled}
            register={register}
            errors={errors}
            watch={watch}
            control={control}
          />
          <Styled.FieldsRow $gutter>
            <RegistrationFormSimpleTerms
              register={register}
              linkComponents={[Styled.PrivacyNoticeLink, Styled.TermsOfUseLink]}
            />
          </Styled.FieldsRow>

          <RegistrationFormReCaptcha reCaptchaProps={reCaptchaProps} captchaError={captchaError} />

          <RegistrationFormSubmit disabled={disabled} processing={processing}>
            Register
          </RegistrationFormSubmit>
        </div>
      </Styled.RegistrationForm>
    )
  }
