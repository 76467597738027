import { bind } from '@react-rxjs/core'
import { createSignal } from '@react-rxjs/utils'
import { filter, map, switchMap, tap } from 'rxjs'
import { RegistrationData, RegistrationPrivateLabelUnlicensedUserMetadata } from '../types'
import { isNotNull } from './filter'
import { registrationRequest } from './signup'
import { setRegistrationSuccess } from './success'

const [privateLabelUnlicensedRegistrationInput$, setPrivateLabelUnlicensedRegistrationInput] =
  createSignal<{
    input: RegistrationData & RegistrationPrivateLabelUnlicensedUserMetadata
  }>()

// create hook for sharing latest input pending subscription to request observable
const [
  useLatestPrivateLabelUnlicensedRegistrationInput,
  latestPrivateLabelUnlicensedRegistrationInput$,
] = bind(privateLabelUnlicensedRegistrationInput$, null)

export {
  setPrivateLabelUnlicensedRegistrationInput,
  useLatestPrivateLabelUnlicensedRegistrationInput,
}

export const privateLabelUnlicensedRegistrationRequest$ =
  latestPrivateLabelUnlicensedRegistrationInput$.pipe(
    filter(isNotNull),
    switchMap(({ input }) =>
      registrationRequest({ input, isGuestCheckoutEnabled: false }).pipe(
        map((response) => ({ input, response })),
        tap(setRegistrationSuccess)
      )
    )
  )
