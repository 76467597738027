import React, { FC } from 'react'
import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import { Layout } from '../../global/Layout'
import iconAtom from '../../../images/registration/atom.svg'
import * as Styled from './styled'

export const RegistrationLayout: FC = ({ children }) => {
  return (
    <>
      <GlobalStyle includeTypography />
      <Layout>
        <Styled.Container>
          <Styled.LeftSection>
            <Styled.Logo />
            <Styled.Heading>
              The health care professional&apos;s trusted source since 1989
            </Styled.Heading>
            <Styled.LeftSectionBottom>
              <Styled.AtomIcon src={iconAtom} alt="Designs for Health" />
              <Styled.LeftSectionBottomTitle>
                Science Based Formula Design
              </Styled.LeftSectionBottomTitle>
              <Styled.ProgressBar>
                <Styled.ProgressBarIndicatorActive />
                <Styled.ProgressBarIndicator />
                <Styled.ProgressBarIndicator />
                <Styled.ProgressBarIndicator />
              </Styled.ProgressBar>
            </Styled.LeftSectionBottom>
          </Styled.LeftSection>
          <Styled.RightSection>{children}</Styled.RightSection>
        </Styled.Container>
      </Layout>
    </>
  )
}
